import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<strong parentName="p">{`Vigilant`}</strong>{` are members of the `}<a parentName="p" {...{
        "href": "/Vigil",
        "title": "Vigil"
      }}>{`Vigil`}</a>{` in `}<a parentName="p" {...{
        "href": "/Meripol",
        "title": "Meripol"
      }}>{`Meripol`}</a>{`. They represent Meripol's police and detective force, charged with keeping peace, investigating crimes, and arresting criminals. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      